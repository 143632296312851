import Vue from 'vue'
import VueRouter from 'vue-router'
import jwt from '@/auth/jwt/sclUseJwt'
import NProgress from 'nprogress'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Dashboard',
        requiresLogin: true,
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/reviewpayments',
      name: 'reviewpayments',
      component: () => import('@/views/ReviewPayments.vue'),
      meta: {
        pageTitle: 'Review payments',
        requiresLogin: true,
        breadcrumb: [
          {
            text: 'Review payments',
            active: true,
          },
        ],
      },
    },
    {
      path: '/submittosage',
      name: 'submittosage',
      component: () => import('@/views/ReviewSageExports.vue'),
      meta: {
        pageTitle: 'Submit to Sage',
        requiresLogin: true,
        breadcrumb: [
          {
            text: 'Submit to Sage',
            active: true,
          },
        ],
      },
    },
    {
      path: '/createoperative',
      name: 'createoperative',
      component: () => import('@/views/CreateOperative.vue'),
      meta: {
        pageTitle: 'Create Operative',
        requiresLogin: true,
        breadcrumb: [
          {
            text: 'Create Operative',
            active: true,
          },
        ],
      },
    },
    {
      path: '/operativeedit',
      name: 'operativeedit',
      component: () => import('@/views/OperativeEditor.vue'),
      meta: {
        pageTitle: 'Operative',
        requiresLogin: true,
        breadcrumb: [
          {
            text: 'Operative',
            active: true,
          },
        ],
      },
    },
    {
      path: '/operatives',
      name: 'operatives',
      component: () => import('@/views/Operatives.vue'),
      meta: {
        pageTitle: 'Operatives',
        requiresLogin: true,
        breadcrumb: [
          {
            text: 'Operatives',
            active: true,
          },
        ],
      },
    },
    {
      path: '/applicantedit',
      name: 'applicantedit',
      component: () => import('@/views/ApplicationEditor.vue'),
      meta: {
        pageTitle: 'Application',
        requiresLogin: true,
        breadcrumb: [
          {
            text: 'Application',
            active: true,
          },
        ],
      },
    },
    {
      path: '/applicants',
      name: 'applicants',
      component: () => import('@/views/Applicants.vue'),
      meta: {
        pageTitle: 'Applicants',
        requiresLogin: true,
        breadcrumb: [
          {
            text: 'Applicants',
            active: true,
          },
        ],
      },
    },
    {
      path: '/companyeditor',
      name: 'companyeditor',
      component: () => import('@/views/CompanyEditor.vue'),
      meta: {
        pageTitle: 'Company editor',
        requiresLogin: true,
        breadcrumb: [
          {
            text: 'Company editor',
            active: true,
          },
        ],
      },
    },
    {
      path: '/resetpassword',
      name: 'resetpassword',
      component: () => import('@/views/ResetPassword.vue'),
      meta: {
        requiresLogin: false,
        layout: 'full',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        requiresLogin: false,
        layout: 'full',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/Logout.vue'),
      meta: {
        requiresLogin: false,
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        requiresLogin: false,
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  if (to.name === 'login') {
    next()
    return
  }
  // console.log('1')
  if (to.matched.some(record => record.meta.requiresLogin) && jwt !== undefined) {
    // console.log('2')
    if (jwt.getToken() !== null && jwt.getToken().length) {
      // console.log('3')
      next()
    } else {
      // console.log('4')
      next({
        name: 'login',
      })
    }
  } else if (to.name === 'resetpassword') {
    // console.log('A')
    next()
  } else {
    // console.log('B')
    next({
      name: 'login',
    })
  }
})

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (from) {
    // nothing
  }
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  if (to || from) {
    // nothing
  }

  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
