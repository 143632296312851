import axios from '@axios'
import SclJwtService from '@/auth/jwt/sclJwtService'

const jwt = new SclJwtService(axios, {
  loginEndpoint: '/token/',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/tokenupdate/',
  logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'access',
  storageRefreshTokenKeyName: 'refresh',
})

export default jwt
